import React, { Component } from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";

import {DICTIONARY} from "../../lib/dictionaries";
import detectLanguage from "../../helpers/detectLanguage";
// Actions
import { fetchInvitation, fetchInvitationRegistration } from "./actions";
import { fetchLogout } from "../login/actions";

// Config
import routes from "../../config/routes";

// Components
import LinkAnchor from "../../components/LinkAnchor";
import Select from "../../components/Select";
import Input from "../../components/Input";
import Datepicker from "../../components/Datepicker";
import IllustrationBoxOne from "../../components/IllustrationBoxOne";
import IllustrationBoxTwo from "../../components/IllustrationBoxTwo";
import IllustrationBoxThree from "../../components/IllustrationBoxThree";
import Spinner from "../../components/Spinner";
import Checkbox from "../../components/Checkbox";
import MultiAnswers from "../../components/MultiAnswers";
import RadioButton from "../../components/RadioButton";

import ProcedureClient from "../procedures/ProcedureClient";
import SanitasBiodataProcedure from "../procedures/SanitasBiodataProcedure";

const STEP_KILLERS = 'STEP_KILLERS';
const STEP_AUTH = 'STEP_AUTH';
const STEP_BIODATA = 'STEP_BIODATA';
const STEP_CONSENT = 'STEP_CONSENT'

//localize
import internalization from "../../config/localize"

var languageUserAgent = detectLanguage()
internalization.setLanguage(languageUserAgent)

class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      killers: [],
      biodata: [],
      auth_fields: ["email", "password", "password_confirmation"],
      biodata_required: [],
      error_object: {},
      biodata_enabled: true,
      sanitas_bioadata: false,       
      user: {
        profile_attributes: {},
      },
      step: '',
      buttonDisabled: true,
      submitting: false,
      loading: true,
    };

    this.paginationWrapper = React.createRef();
  }

  componentDidMount = () => {
    this.props.fetchLogout(() => this._sucessLogout(), () => this._sucessLogout())
    var that = this;
    // $(".c-input").on('keyup', function (e) {
    //   if (e.key === 'Enter' || e.keyCode === 13) {
    //     that._keyEnterPressed()
    //   }
    // });
  };

  _keyEnterPressed = (e, state) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      this._navigateFrom(this.state, this.state.step)
        e.preventDefault()
        e.stopPropagation()
      }
  }

  _sucessLogout = () => {
    this.props.fetchInvitation(this.props.match.params.user_folder_token, (responseJson) => this._successFetchInvitation(responseJson), (responseJson) => this._errorInvitation(responseJson));
  }

  _successFetchInvitation = (responseJson) => {

    if(responseJson.data && responseJson.data.attributes) {
      this.setState({sanitas_bioadata: responseJson.data.attributes.sanitas_bioadata})
      if(responseJson.data && responseJson.data.attributes && responseJson.data.attributes.hasOwnProperty("profile_info")){
        let profile_info = responseJson.data.attributes.profile_info
        if(profile_info.hasOwnProperty("language")){
          internalization.setLanguage(profile_info["language"])
        }
      }
      let data = responseJson.data.attributes;
      let steps_completed = []

      this.setState({consent: data.consent, killers: data.killers, biodata: data.biodata , steps_completed: steps_completed}); 
      if (Object.keys(data.killers).length > 0 && Object.keys(data.killers.killer_questions).length > 0 && !steps_completed.includes(STEP_KILLERS) ) {
        this.setState({step: STEP_KILLERS, loading: false});
      } else if (data.biodata.length > 0 && !steps_completed.includes(STEP_KILLERS) ) {
        this._navigateFrom(this.state, STEP_KILLERS)
      } else if (false) {

      }
      else{
        this.setState({step: STEP_AUTH, loading: false})
      }
    }
  }

  _errorInvitation = (responseJson) => {
    let message_error = {"title": "Ha habido un error"}
    
    if(responseJson.hasOwnProperty("message_error")){

      message_error = {
        "title": internalization.invitation[responseJson.message_error["title"]],
        "description": internalization.invitation[responseJson.message_error["description"]]
      }
    }
    // let message_error = responseJson.hasOwnProperty("message_error") ? responseJson.message_error : {title: "Ha habido un error"}
    this.props.history.push(routes.client.home, {message_error: message_error})
  }

  _setUserState = (type, value) => {
    this.setState((prevState) => ({
      ...prevState,
      user: { ...prevState.user, [type]: value },
    }));
  };

  _validateRequired = (state) => {
    let button_disabled = true
    let error_object = {}
    this._elementsToCheckRequired(state).forEach(element => {
      let to_check = state.user.profile_attributes[element.key]
      if(state.auth_fields.includes(element.key)){
        to_check = state.user[element.key]
      }
      if(to_check === "" || to_check === undefined){
        error_object[element.key+"_error_label"] = internalization.form.empty
      }
      button_disabled = button_disabled && (to_check !== "" && to_check !== undefined)
    });
    if(state.step === STEP_AUTH && state.biodata.filter((field) => ["password"].includes(field.key)).length > 0 ){
      let passwords_errors = this._checkPasswordValidation(state, error_object)
      Object.assign(error_object, passwords_errors)
    }
    if(state.step === STEP_AUTH && state.biodata.filter((field) => ["email"].includes(field.key)).length > 0 ){
      let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let valid_email = re.test(state.user.email);
      if(!valid_email){
        error_object["email_error_label"] = internalization.form.email_not_valid
      }
    }
   
    this.setState({error_object: error_object})
    return Object.keys(error_object).length === 0
  }

  _elementsToCheckRequired(state){
    let elements_to_check = []
    if (state.step === STEP_AUTH) {
      elements_to_check = this._biodataAuthFields(state).filter((element) => element.required == true)
    }
    if (state.step === STEP_BIODATA) {
      elements_to_check = this._biodataFields(state).filter((element) => element.required == true)
    }
    return elements_to_check   
  }

  _setProfileAttributesState = (state, type, value) => {
    if(type == "language"){
      internalization.setLanguage(value)
    }    
    this.setState((prevState) => ({
      ...prevState,
      user: {
        ...prevState.user,
        profile_attributes: {
          ...prevState.user.profile_attributes,
          [type]: value,
        },
      }
    }));
  };

  _checkPasswordValidation = (state, error_object) => {
   
    if(state.user.password && (state.user.password.length === 0 || state.user.password.length < 8) ){
      error_object["password_error_label"] = internalization.form.password_length
    }
    if(state.user.password_confirmation !== state.user.password) {
      error_object["password_confirmation_error_label"] = internalization.form.password_mismatch
    }
    return error_object    
  }

  _submit = (state) => {
    this.setState({submitting: true})
    this.props.fetchInvitationRegistration(this.props.match.params.user_folder_token,
      { user_folder: state.user },
      (auth_token) => this._success(auth_token),
      () => this._error()
    );
  };

  _success(){
    this.props.history.replace("/");
    // window.location.href = "/"
  }

  _error(){
    this.setState({submitting: false})
  }
  
  _renderBiodataAuth = (state) => {
    return (
      <motion.div
        className="o-display--flex@lg-and-up"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        key={state.step}
      >
        <div className="o-col--1/2@lg o-pos-rel">
          <form>
            {
              this._biodataAuthFields(state).map((element, index) => {
                console.log(state.error_object[element.key+"_error_label"] !== undefined)
                if(element.key === "email"){
                  return(
                    <Input
                      label={internalization.form.email}
                      isRequired={element.required}
                      showError={state.error_object[element.key+"_error_label"] !== undefined}
                      errorLabel={state.error_object[element.key+"_error_label"] || internalization.form.empty}              
                      minCharacters={5}
                      type="text"
                      value={state.user[element.key]}
                      onKeyPress={(e) => this._keyEnterPressed(e, this.state)}
                      onChange={(e) => this._setUserState("email", e.currentTarget.value)}
                    />
                  )
                }
                if(["password", "password_confirmation"].includes(element.key)){
                  return(
                    <React.Fragment>
                      <Input
                        label={internalization.form[element.key]}
                        minCharacters={8}
                        isRequired={element.required}
                        errorLabel={state.error_object[element.key+"_error_label"]}
                        showError={state.error_object[element.key+"_error_label"] !== undefined}              
                        hintLabel={internalization.form.password_length}
                        showHint={state.error_object[element.key+"show_error"] ? false : true}              
                        type="password"
                        value={state.user[element.key]}
                        onChange={(e) =>{ this._setUserState(element.key, e.currentTarget.value)}}
                      />
                    </React.Fragment>
                  )
                }

              })
            }
            {/*<p className="o-margin--bottom-size-l" style={{fontWeight: "bold"}}>{internalization.form.process_password_info}</p>*/}
          </form>
        </div>
        <div className="o-col--1/2@lg o-display--flex o-display--flex-h-right o-display--flex-v-center u-hide@sm-and-down o-margin--top-size-xxl@md-only o-pos-rel">
          <IllustrationBoxOne />
        </div>
      </motion.div>
    );
  };

 _preareGenderTemporary = (key) => {
  if(key === "men") return 0
  if(key === "women") return 1
  if(key === "other") return 2
  return key
 }
  _selectItemOptions = (element) => {
    if(element.hasOwnProperty("options")){
      if(["language", "gender"].includes(element.key)){
        return element.options.map((option, index) => { 
          return { 
            value: element.key === "gender" ? this._preareGenderTemporary(option.key) : option.key, text: internalization.form[element.key+"_"+option.key], 
            option: {key :element.key === "gender" ? this._preareGenderTemporary(option.key) : option.key, title: internalization.form[element.key+"_"+option.key] } 
          }
        } 
        )
      }else{
        return element.options.map((option, index) => { return { value: option.key, text: option.title, option }} )    
      }
      
    }else if(element.hasOwnProperty("dictionary_options") && ["country"].includes(element.key)){
      return DICTIONARY["profile"][element.dictionary_options].map((value) => { return { value: value[0], text: value[1], option: {key: value[0]} }})  
    }else if(element.hasOwnProperty("dictionary_options") && element.key !== "country"){
      // return DICTIONARY["profile"][element.dictionary_options].map((value) => { return { value: value, text: value, option: {key: value} }})  
      return internalization.dictionaries[element.key+"s"].map((value) => { return { value: value[0], text: value[1], option: {key: value[0]} }})
    }
  }

  _renderConsent = (state) => {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        key={state.step}
        className="o-display--flex@md-and-up o-display--flex-column-reverse@md-only"
      >
        <div dangerouslySetInnerHTML={{ __html: state.consent.consent }}></div>
      </motion.div>
    )
  }

  _renderBiodataScreen = (state) => {
    let fileRefRadiobutton = 0
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        key={state.step}
        style={{marginBottom: 24}}
        className="o-display--flex@md-and-up o-display--flex-column-reverse@md-only"
      >
        <div className="o-col--1/2@lg o-display--flex o-display--flex-v-center u-hide@sm-and-down o-pos-rel">
          <IllustrationBoxTwo />
        </div>
        <div className="o-col--1/2@lg o-pos-rel">
          <form>
            {
              state.biodata.length > 0 && this._biodataFields(state).map((element, index) => {
                if(element.item_format_style == "Label"){
                  return (
                    <p className='o-margin--bottom-size-m u-text--headingH6'> {element.title} </p>
                  )
                }
                if(element.item_format_style == "text"){
                  return (
                    <Input
                      key={element.id}
                      label={internalization.form[element.key]}
                      isRequired={element.required}
                      errorLabel={state.error_object[element.key+"_error_label"]}
                      showError={state.error_object[element.key+"_error_label"] !== undefined}              
                      type="text"
                      onKeyPress={(e) => this._keyEnterPressed(e, this.state)}
                      value={state.user.profile_attributes[element.key] !== undefined ? state.user.profile_attributes[element.key] : ""}
                      onChange={(e) => this._setProfileAttributesState(this.state, ""+element.key, e.target.value) }
                    />
                  )
                }
                if(element.item_format_style == "textarea"){
                  return (
                    <Input
                      key={element.id}
                      label={internalization.form[element.key]}
                      isRequired={element.required}
                      errorLabel={state.error_object[element.key+"_error_label"]}
                      showError={state.error_object[element.key+"_error_label"] !== undefined}              
                      type="textarea"
                      value={state.user.profile_attributes[element.key] || ""}
                      onChange={(e) => this._setProfileAttributesState(this.state, ""+element.key, e.target.value)}
                    />
                  )
                }                
                if(element.item_format_style == "radio"){
                  fileRefRadiobutton ++;
                  return (
                    <div key={element.id} style={{paddingLeft: element.grouped == true ? 20 : 0}} className={'o-margin--bottom-size-l'}>
                      <label className="c-input__label">
                        {element.required && 
                          <span class="o-color--red">*{" "}</span>
                        }
                        {element.title}
                      </label>
                      <div 
                        className={"c-radio-button__options c-procedure-radio_button__options"}
                      >
                        <div>
                          <RadioButton
                            key={index}
                            text={option.title}
                            isSelected={state.user.profile_attributes[element.key] === option.key}
                            onClick={() => this._setProfileAttributesState(this.state, ""+element.key,  option.key)}
                            editable={true}
                          />
                        </div>
                      </div>
                      { state.error_object[element.key+"_error_label"] !== undefined && 
                      <span className="c-input__error">
                        {state.error_object[element.key+"_error_label"]}
                      </span>
                      }                     
                    </div>
                  )
                }
                if(element.item_format_style == "select"){
                  console.log("element.key", element.key)
                  return (
                    <div key={element.id} className="c-input__wrapper" style={{paddingLeft: element.grouped == true ? 20 : 0}}>
                      <label className="c-input__label">
                          {element.required && 
                            <span class="o-color--red">*{" "}</span>
                          }
                          {internalization.form[element.key]}
                      </label>
                      <div 
                        className={"c-procedure-radio_button__options"}
                        style={{ alignItems:  'flex-start' }}
                      >
                        
                        <Select
                          withoutInput={true}
                          hasError={(value) => {}}
                          erroLabel='Error'
                          valueSelected={state.user.profile_attributes[element.key] === undefined ? "" : state.user.profile_attributes[element.key]}
                          options={this._selectItemOptions(element)}
                          optionClick={(text, value, data) => this._setProfileAttributesState(this.state, ""+element.key,  data.option.key) }
                          wrapperClassName='c-procedure__select'
                        />
                      </div>
                      { state.error_object[element.key+"_error_label"] !== undefined && 
                      <span className="c-input__error">
                        {state.error_object[element.key+"_error_label"]}
                      </span>
                      }                     
                    </div>
                  )
                }                
                if(element.item_format_style == "checkbox"){
                  return (
                    <React.Fragment>
                      <MultiAnswers 
                        key={element.id}
                        editable={true}
                        data={element}
                        isRequired={element.required}
                        handleFiles={(e, key) => ""}
                        selectedElements={(keys) =>  this._setProfileAttributesState(this.state, ""+element.key,  keys) }
                        filesUplodaded={state.user.profile_attributes["files"][element.id]}
                        deleteFile={(key) => ""}
                      />
                      { state.error_object[element.key] !== undefined && 
                        <span className="c-input__error">
                          {state.error_object[element.key]}
                        </span>
                      }
                    </React.Fragment>
                  )
                }

                if(element.item_format_style == "date"){
                  return (
                    <React.Fragment>
                      <Datepicker
                        isRequired={element.required}
                        hasTime={false}
                        initDateValue={""}
                        label={internalization.form[element.key]}
                        errorLabel={state.error_object[element.key+"_error_label"]}
                        showError={state.error_object[element.key+"_error_label"] !== undefined}              
                        onChange={(dateValue) => this._setProfileAttributesState(this.state, ""+element.key,  dateValue)}
                      />
                      { state.error_object[element.key] &&
                        <span className="c-input__error">
                          {state.error_object[element.key]}
                        </span>
                      }
                    </React.Fragment> 
                  )         
                }

                 if(element.item_format_style == "subject_id"){
                  return (
                    <Input
                      key={element.id}
                      label={"Subject ID"}
                      isRequired={element.required}
                      errorLabel={state.error_object[element.key+"_error_label"]}
                      showError={state.error_object[element.key+"_error_label"] !== undefined}              
                      type={"text"}
                      placeholder={"XXXYYYY"}
                      pattern={"[0-9]+"}
                      showHint={true}
                      hintLabel={"XXX as site number and YYYY as subject number"}
                      value={state.user.profile_attributes[element.key] !== undefined ? state.user.profile_attributes[element.key] : ""}
                      onChange={(e) => {
                         const sanitizedValue = e.target.value.replace(/\D/g, '');

                        // Limit to 9 digits
                        const truncatedValue = sanitizedValue.slice(0, 7);

                        this._setProfileAttributesState(this.state, ""+element.key, truncatedValue)
                        }   
                      }
                    />
                  )
                }                      
              })
            }
          </form>
        </div>
      </motion.div>
    )    
  }

  _navigateFrom(state, step, responseJSON = undefined) {
    if (step === STEP_KILLERS) {
      //COMPROBAR LOS SIGUIENTES PASOS
      if (state.biodata.length > 0 && this._biodataAuthFields(state).length > 0) {
        this.setState({step: STEP_AUTH, loading: false});
      } else if (state.biodata.length > 0 && this._biodataFields(state).length > 0) {
        this.setState({step: STEP_BIODATA, loading: false});
      } else {
        if(responseJSON && responseJSON.link){
          window.location = responseJSON.link
        }
        // this._submit(state) //TODO[cmuino]: acabar de decidir
      }
    }
    if (step === STEP_AUTH) {
      //COMPROBAR LOS SIGUIENTES PASOS
      if (this.state.sanitas_bioadata){
        let validated = this._validateRequired(state)
        validated && this.setState({step: STEP_BIODATA});
      } else if (state.biodata.length > 0 && this._biodataFields(state).length > 0) {
        let validated = this._validateRequired(state)
        validated && this.setState({step: STEP_BIODATA});
      } else if (state.consent.consent_enabled) {
        this.setState({step: STEP_CONSENT})
        this._setProfileAttributesState(state, "consent", true)        
      } else {
        this._submit(state) //TODO[cmuino]: acabar de decidir
      }
    }
    if (step === STEP_BIODATA) {
      let validated = this._validateRequired(state)
      if (state.consent.consent_enabled) {
        this.setState({step: STEP_CONSENT});
        this._setProfileAttributesState(state, "consent", true)
      }else{
        validated && this._submit(state)        
      }
    }

    if (step === STEP_CONSENT) {
      this._submit(state)
    }

  }

  _biodataAuthFields(state) {
    return state.biodata.filter((field) => state.auth_fields.includes(field.key))
  }

  _biodataFields(state) {
    return state.biodata.filter((field) => !state.auth_fields.includes(field.key))
  }

  _submitFromSanitas(data) {
    this.setState((prevState) => ({
      ...prevState,
      user: {
        ...prevState.user,
        profile_attributes: {
          ...prevState.user.profile_attributes,
          ...data,
        },
      }
    }), () => this._submit(this.state))

  }

  render = () => {
    console.log(this.state.sanitas_bioadata, this.state.step)
    return (
      <motion.section
        className="o-wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="o-container">
          {
            this.state.step !== STEP_KILLERS && this.state.step !== STEP_CONSENT && 
            <div className="u-width--1/2@lg">
              <h1 className="o-margin--bottom-size-l">{internalization.form.some_data}</h1>
              <p className="o-margin--bottom-size-l">{internalization.form.know_you}</p>
            </div>
          }
          

          {
            this.state.step === STEP_KILLERS &&
            <ProcedureClient
              killer_questions={this.state.killers}
              token={this.props.match.params.user_folder_token}
              navigate={(responseJSON) => this._navigateFrom(this.state, STEP_KILLERS, responseJSON)}
              setEmail={(email) =>  this._setUserState("email", email)}
            />
          }

          {
          this.state.step === STEP_BIODATA && this.state.sanitas_bioadata && 
            <SanitasBiodataProcedure 
              submit={(data) => this._submitFromSanitas(data)
              }
            />
          }

          {this.state.step === STEP_AUTH && this._renderBiodataAuth(this.state)}
          {this.state.step === STEP_BIODATA && !this.state.sanitas_bioadata && this._renderBiodataScreen(this.state)}
          {this.state.step === STEP_CONSENT && this._renderConsent(this.state)}

          <div ref={this.paginationWrapper} className="c-pagination--simple">
            {
              this.state.step !== STEP_KILLERS && ((this.state.sanitas_bioadata && this.state.step === STEP_AUTH) || !this.state.sanitas_bioadata) && (
                <motion.button
                  id={"navigateFromButton"}
                  className="c-button"
                  onClick={() => this._navigateFrom(this.state, this.state.step)}
                >
                  <span className="c-button__text">{this.state.step === STEP_CONSENT ? internalization.buttons.accept : internalization.buttons.continue}</span>
                </motion.button>
              )
            }
          </div>
        </div>
        {this.state.loading && <Spinner />}
      </motion.section>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    surveyToken: state.user.surveyToken,
    errors: state.global.errors && state.global.errors.invitationRegistration,
  };
};

const mapDispatchToProps = {
  fetchInvitation,
  fetchInvitationRegistration,
  fetchLogout
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
