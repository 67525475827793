import {
  call,
  put,
  putResolve,
  take,
  takeLatest,
  all,
} from "redux-saga/effects";
import {
  FETCH_ORGANIZATIONS,
  FETCH_ORGANIZATION,
  FETCH_UPDATE_ORGANIZATION,
  FETCH_ORGANIZATION_GET_ASSESSMENTS,
  FETCH_ORGANIZATION_CREATE,
  FETCH_FOLDER_INDEX,
  FETCH_FOLDER,
  FETCH_FOLDER_CREATE,
  FETCH_FOLDER_UPDATE,
  FETCH_COPY_CREATE,
  FETCH_SWITCH_CONFIG_FOLDER,
  FETCH_FOLDER_DESTROY,
  FETCH_FOLDER_CREATE_USERS_FOLDER,
  FETCH_FOLDER_UPDATE_USERS_FOLDER,
  FETCH_FOLDER_DESTROY_USER,
  FETCH_FOLDER_SEND_INVITATION_USER,
  FETCH_FOLDER_IMPORT_INVITATION_USER,
  FETCH_FOLDER_START_SURVEY,
  FETCH_FOLDER_UPDATE_CHART,
  FETCH_FOLDER_DOWNLOAD_SCORE_USERS,
  FETCH_FOLDER_DOWNLOAD_REPORT,
  FETCH_EMAILS_FROM_ORGANIZATION,
  FETCH_EMAILS_CREATE,
  FETCH_EMAILS_UPDATE,
  FETCH_EMAILS_DESTROY_FROM_ORGANIZATION,
  FETCH_EMAILS_SHOW,
  FETCH_EMAILS_SEND,
  FETCH_EMAILS_FROM_PROCEDURE,
  FETCH_EMAILS_PROCEDURE_CREATE,
  FETCH_EMAILS_PROCEDURE_UPDATE,
  FETCH_CONSENT_PROCEDURE_UPDATE,
  FETCH_CONSENT_FOLDER_UPDATE,
  FETCH_EMAILS_DESTROY_FROM_PROCEDURE,
  FETCH_EMAILS_PROCEDURE_SHOW,
  FETCH_EMAILS_PROCEDURE_SEND,
  FETCH_USER,
  FETCH_USER_DELETE_ALL_ANSWERS,
  FETCH_USER_DELETE_MODULE_ANSWER,
  FETCH_USER_UPDATE,
  FETCH_ADMINS,
  FETCH_SHOW_ADMIN,
  FETCH_ADMIN_UPDATE,
  FETCH_ADMIN_CREATE,
  FETCH_ADMIN_DESTROY,
  FETCH_ADMIN_SEND_EMAIL,
  FETCH_LOGS_FROM_ORGANIZATION,
  FETCH_PROCEDURES_INDEX,
  FETCH_PROCEDURE_CREATE,
  FETCH_PROCEDURE,
  FETCH_PROCEDURE_USER,
  FETCH_PROCEDURE_UPDATE,
  FETCH_PROCEDURE_ADD_USERS,
  FETCH_PROCEDURE_REMOVE_USERS,
  FETCH_DELETE_DOCUMENT_PROCEDURE_USER,
  fetchOrganizationsOk,
  fetchOrganizationOk,
  fetchOrganizationGetAssessmentsOk,
  fetchFolderIndexOk,
  fetchFolderOk,
  fetchFolderCreateOk,
  fetchFolderUpdateOk,
  fetchFolderDestroyOk,
  fetchFolderCreateUsersFolderOk,
  fetchFolderDestroyUserOk,
  fetchFolderSendInvitationUserOk,
  fetchFolderUpdateChartOk,
  fetchEmailsFromOrganizationOk,
  fetchEmailsCreateOk,
  fetchEmailsUpdateOk,
  fetchEmailsDestroyFromOrganizationOk,
  fetchEmailsShowOk,
  fetchEmailsSendOk,
  fetchUserOk,
  fetchUserDeleteAllAnswersOk,
  fetchUserDeleteModuleAnswerOk,
  fetchUserUpdateOk,
  fetchAdminsOk,
  fetchAdminDestroyOk,
  fetchLogsFromOrganizationOk,
  fetchProceduresIndexOk,
  fetchProcedureCreateOk,
  fetchProcedureOk,
  fetchProcedureUserOk,
  FETCH_KILLER_EXPORT,
  FETCH_KILLER_FILES,
  FETCH_KILLER_FILES_ALL,
  FETCH_KILLER_EXPORT_ALL,
  FETCH_UPLOAD_DOCUMENT_PROCEDURE_USER,
  FETCH_UPLOAD_DOCUMENT_PROCEDURE_USER_OK,
  fetchEmailsFromProcedureOk,
  fetchEmailsProcedureCreateOk,
  fetchEmailsProcedureUpdateOk,
  fetchEmailsDestroyFromProcedureOk,
  fetchEmailsProcedureShowOk,
  fetchEmailsProcedureSendOk, 
  FETCH_EMAILS_TEST_SEND,
  FETCH_UPDATE_PROCEDURE_USER,
  FETCH_RANKING_PROFILE,
  FETCH_RANKING_PROFILE_ID,
  FETCH_UPDATE_RANKING_PROFILE,
  FETCH_DESTROY_RANKING_PROFILE,
  FETCH_CREATE_RANKING_PROFILE,
  FETCH_RANKED_USERS,
  FETCH_MOVE_USERS,
  FETCH_USER_FOLDER_NOTIFICATION,
  GET_USER_FOLDER_NOTIFICATIONS,
  DELETE_USER_FOLDER_NOTIFICATIONS
} from "./actions";
import Api from "../../services/api";

// Config
import roles from "../../config/roles";
import routes from "../../config/routes";


/* ORGANIZATIONS */
function* fetchOrganizationsSaga(action) {
  try {
    let response = yield call(
      Api.handleGet,
      routes.organizations.indexOrganizations,
      {
        "q[organization_registrations_role_eq]": roles.organization.clientAdmin,
      },
      {}
    );

    if (response.status) {
      yield put(fetchOrganizationsOk(response.data));
      action.data.success && action.data.success()
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchOrganizationSaga(action) {
  try {
    let response = yield call(
      Api.handleGet,
      routes.organizations.showOrganization(
        action.data.organizationId,
        action.data.page,
        action.data.number, 
        action.data.list
      ),
      {
        "q[organization_registrations_role_eq]": roles.organization.clientAdmin,
      },

      {}
    );

    if (response.status) {
      action.data.success && action.data.success(response)
      yield put(fetchOrganizationOk(response));
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchUpdateOrganizationSaga(action) {
  try {
    let response = yield call(
      Api.handlePut,
      routes.organizations.updateOrganization(
        action.data.organizationId,
      ),
      {
        "q[organization_registrations_role_eq]": roles.organization.clientAdmin,
      },

      action.data.data
    );

    if (response.status) {
      action.data.success && action.data.success()
      yield put(fetchOrganizationOk(response));
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchOrganizationGetAssessmentsSaga(action) {
  try {
    let response = yield call(
      Api.handleGet,
      routes.organizations.assessmentsOrganization(
        action.data.organizationId,
      ),
      {},
      {}
    );

    if (response.status) {
      action.data.success()
      yield put(fetchOrganizationGetAssessmentsOk(response));
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchOrganizationCreateSaga(action) {
  try {
    let response = yield call(
      Api.handlePost,
      routes.organizations.indexOrganizations,
      {
      },
      action.data.organizationData
    );

    if (response.status) {
      action.data.success && action.data.success()
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

/* */

/* FOLDERS */

function* fetchFolderIndex(action) {
  try {
    let response = yield call(
      Api.handleGet,
      routes.folders.indexFolder(
        action.data.organizationId,
        action.data.page,
        action.numberForPage
      ),
      {},
      {}
    );

    if (response.status) {
      action.data.success(response.data)
      if(action.data.update_store){
        yield put(fetchFolderIndexOk(response.data));  
      }
      
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}



function* fetchFolderSaga(action) {
  try {
    let response = yield call(
      Api.handleGet,
      routes.folders.showFolder(
        action.data.organizationId,
        action.data.folderId,
        action.data.page,
        action.data.number,
        action.data.status,
        action.data.search,
        action.data.modeOrder,
        action.data.typeOrder
      ),
      {},
      {}
    );

    if (response.status) {
      action.data.success && action.data.success()
      yield put(fetchFolderOk(response.data));
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchFolderCreateSaga(action) {
  try {
    let response = yield call(
      Api.handlePost,
      routes.folders.createFolder(action.data.organizationId),
      {},
      action.data.folder
    );

    if (response.status) {
      yield putResolve(fetchFolderCreateOk(response.data));
      action.data.success();
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchFolderCreateUsersFolder(action) {
  try {
    let response = yield call(
      Api.handlePost,
      routes.folders.createUsersFolder(
        action.data.organizationId,
        action.data.folderId
      ),
      {},
      action.data
    );

    if (response.status) {
      yield putResolve(fetchFolderCreateUsersFolderOk(response.data));
      action.data.success();
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchFolderUpdateUsersFolder(action) {

  try {
    let response = yield call(
      Api.handlePut,
      routes.folders.updateUsersFolder(
        action.data.organizationId,
        action.data.folderId,
        action.data.userFolderId
      ),
      {},
      action.data
    );

    if (response.status) {
      action.data.success();
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchFolderUpdateSaga(action) {
  try {
    let response = yield call(
      Api.handlePut,
      routes.folders.updateFolder(
        action.data.organizationId,
        action.data.folderId
      ),
      {},
      action.data.folder
    );

    if (response.status) {
      yield put(fetchFolderUpdateOk(response.data));
      action.data.success();
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchFolderCopySaga(action) {
  try {
    let response = yield call(
      Api.handlePost,
      routes.folders.copyFolder(
        action.data.organizationId,
        action.data.folderId
      ),
      {},
      action.data.folder
    );

    if (response.status) {
      action.data.success();
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchFolderSwitchConfigSaga(action) {
  try {
    let response = yield call(
      Api.handlePost,
      routes.folders.switchConfigFolder(
        action.data.organizationId,
        action.data.folderId
      ),
      {},
      action.data.folder
    );

    if (response.status) {
      action.data.success();
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}


function* fetchFolderDestroySaga(action) {
  try {
    let response = yield call(
      Api.handleDelete,
      routes.folders.showFolder(
        action.data.organizationId,
        action.data.folderId
      ),
      {},
      {}
    );

    if (response.status) {
      yield putResolve(fetchFolderDestroyOk(action.data.folderId));
      action.data.success();
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchFolderDestroyUser(action) {
  try {
    let response = yield call(
      Api.handlePost,
      routes.folders.destroyUsersFolder(
        action.data.organizationId,
        action.data.folderId
      ),
      {},
      action.data.ids
    );

    if (response.status) {
      yield put(fetchFolderDestroyUserOk(response.data));
      action.data.success();
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchFolderSendInvitationUser(action) {
  try {
    let response = yield call(
      Api.handlePost,
      routes.folders.sendInvitationUsersFolder(
        action.data.organizationId,
        action.data.folderId
      ),
      {},
      action.data.ids
    );

    if (response.status) {
      yield put(fetchFolderSendInvitationUserOk(response.data));
      action.data.success();
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function * fetchStartSurveyFolder(action) {
  try {
    let response = yield call(
      Api.handleGet,
      routes.folders.startSurvey(action.data.organizationId, action.data.folderId),
      {},
    {});

    if(response.status) {
      action.data.success()
    }
    else{
      action.data.error()
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function * fetchFolderUpdateChart(action) {
  try {
    let response = yield call(
      Api.handleGet,
      routes.folders.updateChart(action.data.organizationId, action.data.folderId,action.data.userStatus),
      {},
    {});

    if(response.status) {
      action.data.success(response.data.data.totals)
      yield put(fetchFolderUpdateChartOk(response.data));
    }
    else{
      action.data.error()
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function * fetchFolderDownloadScoreUsers(action) {
  try {
    let response = yield call(
      Api.handleGet,
      routes.folders.downloadScoreUsers(action.data.organizationId, action.data.folderId,action.data.email,action.data.typeCsv),
      {},
    {});

    if(response.status) {
      action.data.success()
    }
    else{
      action.data.error()
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function * fetchFolderDownloadReports(action) {
  try {
    let response = yield call(
      Api.handleGet,
      routes.folders.downloadReports(action.data.organizationId, action.data.folderId, action.data.email),
      {},
    {});

    if(response.status) {
      action.data.success()
    }
    else{
      action.data.error()
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

/* */

/* EMAILS */

function* fetchEmailsFromOrganization(action) {
  try {
    let response = yield call(
      Api.handleGet,
      routes.emails.indexEmailsFromOrganization(
        action.data.organizationId,
        action.data.folderId,
        action.data.page,
        action.data.number
      ),
      {},
      {}
    );

    if (response.status) {
      yield put(fetchEmailsFromOrganizationOk(response.data));
      action.data.success(response.data);
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchEmailsCreate(action) {
  try {
    let response = yield call(
      Api.handlePost,
      routes.emails.createEmails(
        action.data.organizationId,
        action.data.folderId
      ),
      {},
      action.data.email
    );

    if (response.status) {
      yield put(fetchEmailsCreateOk(response.data));
      action.data.success();
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchEmailsUpdate(action) {
  try {
    let response = yield call(
      Api.handlePut,
      routes.emails.updateEmails(
        action.data.organizationId,
        action.data.folderId,
        action.data.idEmail
      ),
      {},
      action.data.email
    );

    if (response.status) {
      yield put(fetchEmailsCreateOk(response.data));
      action.data.success();
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchEmailsDestroyFromOrganization(action) {
  try {
    let response = yield call(
      Api.handleDelete,
      routes.emails.destroyEmailsFromOrganization(
        action.data.organizationId,
        action.data.folderId,
        action.data.emailId
      ),
      {},
      {}
    );

    if (response.status) {
      yield put(fetchEmailsDestroyFromOrganizationOk(response.data));
      action.data.success(response.data);
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchEmailsShow(action) {
  try {
    let response = yield call(
      Api.handleGet,
      routes.emails.showEmails(
        action.data.organizationId,
        action.data.folderId,
        action.data.emailId
      ),
      {},
      {}
    );

    if (response.status) {
      // yield put(fetchEmailsDestroyFromOrganizationOk(response.data));
      action.data.success(response.data);
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchEmailsSend(action) {
  try {
    let response = yield call(
      Api.handlePost,
      routes.emails.sendEmails(
        action.data.organizationId,
        action.data.folderId,
        action.data.emailId,
      ),
      {},
      action.data.ids
    );

    if (response.status) {
     action.data.success(response.message);
    }else {
      action.data.error(response.message);
    }
  } catch (error) {
    
  }
}

function* fetchEmailsSendTestSaga(action) {
  try {
    let response = yield call(
      Api.handlePost,
      routes.emails.sendEmailsTest(
        action.data.organizationId,
        action.data.emailId,
      ),
      {},
      action.data.email
    );

    if (response.status) {
     action.data.success(response.message);
    }else {
      action.data.error(response.message);
    }
  } catch (error) {
    
  }
}
/* */

/* EMAILS PROCEDURE*/

function* fetchEmailsFromProcedureSaga(action) {
  try {
    let response = yield call(
      Api.handleGet,
      routes.procedures_email.indexEmailsFromProcedure(
        action.data.organizationId,
        action.data.procedureId,
        action.data.page,
        action.data.number
      ),
      {},
      {}
    );

    if (response.status) {
      yield put(fetchEmailsFromProcedureOk(response.data));
      action.data.success(response.data);
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchEmailsProcedureCreateSaga(action) {
  try {
    let response = yield call(
      Api.handlePost,
      routes.procedures_email.createEmails(
        action.data.organizationId,
        action.data.procedureId
      ),
      {},
      action.data.email
    );

    if (response.status) {
      yield put(fetchEmailsProcedureCreateOk(response.data));
      action.data.success();
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchEmailsProcedureUpdateSaga(action) {
  try {
    let response = yield call(
      Api.handlePut,
      routes.procedures_email.updateEmails(
        action.data.organizationId,
        action.data.procedureId,
        action.data.idEmail
      ),
      {},
      action.data.email
    );

    if (response.status) {
      yield put(fetchEmailsProcedureCreateOk(response.data));
      action.data.success();
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchConsentProcedureUpdateSaga(action) {
  try {
    let response = yield call(
      Api.handlePut,
      routes.consent.updateConsent(
        action.data.organizationId,
        action.data.procedureId
      ),
      {},
      action.data.consent
    );

    if (response.status) {
      // yield put(fetchConseProcedureCreateOk(response.data));
      action.data.success();
    }
  } catch (error) {
    console.log("error", error)
    // yield put(fetchSearchERR(error))
  }
}

function* fetchConsentFolderUpdateSaga(action) {
  try {
    let response = yield call(
      Api.handlePut,
      routes.consent.updateFolderConsent(
        action.data.organizationId,
        action.data.folderId
      ),
      {},
      action.data.consent
    );

    if (response.status) {
      // yield put(fetchConseProcedureCreateOk(response.data));
      action.data.success();
    }
  } catch (error) {
    console.log("error", error)
    // yield put(fetchSearchERR(error))
  }
}



function* fetchEmailsDestroyFromProcedureSaga(action) {
  try {
    let response = yield call(
      Api.handleDelete,
      routes.procedures_email.destroyEmailsFromProcedure(
        action.data.organizationId,
        action.data.procedureId,
        action.data.emailId
      ),
      {},
      {}
    );

    if (response.status) {
      yield put(fetchEmailsDestroyFromProcedureOk(response.data));
      action.data.success(response.data);
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchEmailsProcedureShowSaga(action) {
  try {
    let response = yield call(
      Api.handleGet,
      routes.procedures_email.showEmails(
        action.data.organizationId,
        action.data.procedureId,
        action.data.emailId
      ),
      {},
      {}
    );

    if (response.status) {
      // yield put(fetchEmailsDestroyFromOrganizationOk(response.data));
      action.data.success(response.data);
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchEmailsProcedureSendSaga(action) {
  try {
    let response = yield call(
      Api.handlePost,
      routes.procedures_email.sendEmails(
        action.data.organizationId,
        action.data.procedureId,
        action.data.emailId,
      ),
      {},
      action.data.ids
    );

    if (response.status) {
     action.data.success(response.message);
    }else {
      action.data.error(response.message);
    }
  } catch (error) {
    
  }
}

/* */
/* USERS */

function* fetchUser(action) {
 
  try {
    let response = yield call(
      Api.handleGet,
      routes.adminUser.user(
        action.data.organizationId,
        action.data.userId,
        action.data.folderId,
      ),
      {},
      {}
    );

    if (response.status) {
     // yield put(fetchEmailsDestroyFromOrganizationOk(response.data));
     action.data.success(response.data);
    }else {
      action.data.error();
    }
  } catch (error) {
    
  }
}

function* fetchUserDeleteAllAnswers(action) {

  try {
    let response = yield call(
      Api.handlePost,
      action.data.url,
      {},
      {}
    );

    if (response.status) {
     // yield put(fetchEmailsDestroyFromOrganizationOk(response.data));
     action.data.success(response.data);
    }else {
      action.data.error();
    }
  } catch (error) {
    
  }
}

function* fetchUserDeleteModuleAnswer(action) {

   try {
     let response = yield call(
       Api.handlePost,
       action.data.url,
       {},
       {}
     );
 
     if (response.status) {
      // yield put(fetchEmailsDestroyFromOrganizationOk(response.data));
      action.data.success(response.data);
     }else {
       action.data.error();
     }
   } catch (error) {
     
   }
 }

 function* fetchUserUpdate(action) {
 
  try {
    let response = yield call(
      Api.handlePut,
      routes.adminUser.user(
        action.data.organizationId,
        action.data.userId,
        action.data.folderId,
      ),
      {},
      action.data.profile
    );

    if (response.status) {
     // yield put(fetchEmailsDestroyFromOrganizationOk(response.data));
     action.data.success(response.data);
    }else {
      action.data.error();
    }
  } catch (error) {
    
  }
}
/* */

/* ADMINS */

function* fetchAdmins(action) {

  try {
    let response = yield call(
      Api.handleGet,
      routes.adminUser.admins(
        action.data.organizationId,
      ),
      {},
      {}
    );

    if (response.status) {
     yield put(fetchAdminsOk(response.data));
     action.data.success(response.data);
    }else {
      action.data.error();
    }
  } catch (error) {
  
  }
}

function* fetchShowAdmin(action) {
  try {
    let response = yield call(
      Api.handleGet,
      routes.adminUser.adminShow(
        action.data.organizationId,
        action.data.adminId,
      ),
      {},
      {}
    );

    if (response.status) {
     action.data.success(response.data);
    }else {
      action.data.error();
    }
  } catch (error) {
    
  }
}

function* fetchAdminUpdate(action) {
  try {
    let response = yield call(
      Api.handlePut,
      routes.adminUser.adminUpdate(
        action.data.organizationId,
        action.data.adminId,
      ),
      {},
      action.data.profile
    );

    if (response.status) {
     action.data.success(response.data);
    }else {
      action.data.error();
    }
  } catch (error) {
    
  }
}

function* fetchAdminCreate(action) {
  try {
    let response = yield call(
      Api.handlePost,
      routes.adminUser.adminCreate(
        action.data.organizationId,
      ),
      {},
      action.data.profile
    );

    if (response.status) {
     action.data.success(response.data);
    }else {
      action.data.error();
    }
  } catch (error) {
    
  }
}

function* fetchAdminDestroy(action) {

  try {
    let response = yield call(
      Api.handlePost,
      routes.adminUser.adminDestroy(
        action.data.organizationId,
      ),
      {},
      action.data.ids
    );

    if (response.status) {
     
      yield put(fetchAdminDestroyOk(response.data));
      action.data.success();
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchAdminSendEmail(action) {
  try {
    let response = yield call(
      Api.handlePost,
      routes.adminUser.adminSendEmail(
        action.data.organizationId,
      ),
      {},
      action.data.ids
    );

    if (response.status) {
      action.data.success(response.message);
    }else {
      action.data.error(response.message);
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

/* */

/* LOGS */

function* fetchLogsFromOrganization(action) {
  try {
    let response = yield call(
      Api.handleGet,
      routes.logs.indexLogsFromOrganization(
        action.data.organizationId,
        action.data.page,
        action.data.number
      ),
      {},
      {}
    );
    if (response.status) {
      yield put(fetchLogsFromOrganizationOk(response.data));
      action.data.success(response.data);
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

/* */

/* PROCEDURES */

function* fetchProceduresIndex(action) {
  try {
    let response = yield call(
      Api.handleGet,
      routes.procedures.proceduresIndex(
        action.data.organizationId,
        action.data.page,
        action.number
      ),
      {},
      {}
    );
    if (response.status) {
      action.data.success()
      yield put(fetchProceduresIndexOk(response.data));
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
};

function* fetchProcedureCreate(action) {
  try {
    let response = yield call(
      Api.handlePost,
      routes.procedures.createProcedure(action.data.organizationId),
      {},
      action.data.procedure
    );

    if (response.status) {
      // yield putResolve(fetchProcedureCreate(response.data));
      action.data.success();
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
};

function* fetchProcedureAddUsers(action) {
  try {
    let response = yield call(
      Api.handlePost,
      routes.procedures.addProcedureUsers(action.data.organization_id, action.data.id),
      {},
      action.data
    );

    if (response.status) {
      // yield putResolve(fetchProcedureCreate(response.data));
      action.data.success();
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
};

function* fetchProcedureRemoveUsers(action) {
  try {
    let response = yield call(
      Api.handlePost,
      routes.procedures.removeProcedureUsers(action.data.organization_id, action.data.id),
      {},
      action.data
    );

    if (response.status) {
      // yield putResolve(fetchProcedureCreate(response.data));
      action.data.success();
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
};


function* fetchProcedure(action) {
  try {
    let response = yield call(
      Api.handleGet,
      routes.procedures.proceduresShow(
        action.data.organizationId,
        action.data.procedureId,
        action.data.page,
        action.data.number,
        action.data.status,
        action.data.search
      ),
      {},
      {}
    );

    if (response.status) {
      action.data.success && action.data.success()
      yield put(fetchProcedureOk(response.data.attributes));
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
};

function* fetchProcedureUser(action) {
  try {
    let response = yield call(
      Api.handleGet,
      routes.procedures.proceduresUserShow(
        action.data.organizationId,
        action.data.procedureId,
        action.data.userId,
      ),
      {},
      {}
    );

    if (response.status) {
      action.data.success && action.data.success()
      yield put(fetchProcedureUserOk(response));
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
};

function* fetchUpateProcedureUser(action) {
  try {
    let response = yield call(
      Api.handlePut,
      routes.procedures.proceduresUpdateUserShow(
        action.data.organizationId,
        action.data.procedureId,
        action.data.procedureUserId
      ),
      {},
      action.data
    );

    if (response.status) {
      action.data.success && action.data.success()
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
};
/* */

function* fetchProcedureUpdate(action) {
  try {
    let response = yield call(
      Api.handlePut,
      routes.procedures.updateProcedure(
        action.data.organizationId,
        action.data.id,
      ),
      {},
      action.data.procedure
    );

    if (response.status) {
      action.data.success();
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchKillerExport(action) {
  try {
    let response = yield call(
      Api.handleGetBlob,
      routes.procedures.killerExportShow(
        action.data.organizationId,
        action.data.procedureId,
        action.data.procedureUserId
        ),
      {},
      {}
    );
    console.log(response)
    // if (response.status) {
    //   action.data.success && action.data.success()
    // }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
};

function* fetchKillerExportAll(action) {
  try {
    let response = yield call(
      Api.handleGetBlob,
      routes.procedures.killerExportAll(
        action.data.organizationId,
        action.data.procedureId
      ),
      {},
      {}
    );
    console.log(response)
    // if (response.status) {
    //   action.data.success && action.data.success()
    // }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
};

function* fetchKillerFiles(action) {
  try {
    let response = yield call(
      Api.handleGet,
      routes.procedures.killerFilesShow(
        action.data.organizationId,
        action.data.procedureId,
        action.data.procedureUserId,
        action.data.email
      ),
      {},
      {}
    );
    console.log(response)
    if (response.status) {
      action.data.success && action.data.success()
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
};

function* uploadDocumentProcedureUser(action) {
  try {
    let response = yield call(
      Api.handlePost,
      routes.procedures.uploadDocumentProcedureUser(
        action.data.organizationId,
        action.data.procedureId,
        action.data.procedureUserId,
      ),
      {},
      action.data.data
    );

    if (response.status) {
      action.data.success();
    }
    else{
      action.data.error()
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }

}

function* deleteDocumentProcedureUser(action) {
  try {
    let response = yield call(
      Api.handlePost,
      routes.procedures.deleteDocumentProcedureUser(
        action.data.organizationId,
        action.data.procedureId,
        action.data.procedureUserId,
      ),
      {},
      {document_id: action.data.documentId},
    );

    if (response.status) {
      action.data.success();
    }
    else{
      action.data.error()
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }

}



function* fetchKillerFilesAll(action) {
  try {
    let response = yield call(
      Api.handleGet,
      routes.procedures.killerFilesAll(
        action.data.organizationId,
        action.data.procedureId,
        action.data.email
      ),
      {},
      {}
    );
    console.log(response)
    if (response.status) {
      action.data.success && action.data.success()
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
};

function* fetchRankingProfileSaga(action) {
  try {
    let response = yield call(
      Api.handleGet,
      routes.folders.indexRankingProfile(
        action.data.organizationId,
        action.data.folderId
      ),
      {},
      {}
    );

    if (response.status) {
      action.data.success && action.data.success(response)
    }
    else{
      action.data.error()
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchRankingProfileIdSaga(action) {
  try {
    let response = yield call(
      Api.handleGet,
      routes.folders.showRankingProfileId(
        action.data.organizationId,
        action.data.folderId,
        action.data.id
      ),
      {},
      {}
    );

    if (response.status) {
      action.data.success && action.data.success(response)
    }
    else{
      action.data.error()
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchCreateRankingProfileSaga(action) {
  try {
    let response = yield call(
      Api.handlePost,
      routes.folders.createRankingProfile(
        action.data.organizationId,
        action.data.folderId
      ),
      {},
      action.data.data
    );

    if (response.status) {
      action.data.success();
    }
    else{
      action.data.error()
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchDestroyRankingProfileSaga(action) {
  try {
    let response = yield call(
      Api.handleDelete,
      routes.folders.destroyRankingProfile(
        action.data.organizationId,
        action.data.folderId,
        action.data.id
      ),
      {},
      {}
    );

    if (response.status) {
      action.data.success && action.data.success()
    }
    else{
      action.data.error()
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchUpdateRankingProfileSaga(action) {
  try {
    let response = yield call(
      Api.handlePut,
      routes.folders.createRankingProfile(
        action.data.organizationId,
        action.data.folderId,
        action.data.id
      ),
      {},
      action.data.data
    );

    if (response.status) {
      action.data.success();
    }
    else{
      action.data.error()
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchRankedUsersSaga(action) {
  console.log("fetchRankedUsersSaga")
  try {
    let response = yield call(
      Api.handleGet,
      routes.folders.getRankedUsers(
        action.data.organizationId,
        action.data.folderId,
        action.data.id
      ),
      {},
      {}
    );

    if (response.status) {
      action.data.success && action.data.success(response)
    }
    else{
      action.data.error()
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchMoveUsersSaga(action) {
  try {
    let response = yield call(
      Api.handlePost,
      routes.folders.fetchMoveUsersSaga(action.data.organizationId),
      {
      },
      action.data.data
    );

    if (response.status) {
      action.data.success && action.data.success()
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* fetchUserFolderNotification(action) {
  try {
    let response = yield call(
      Api.handlePost,
      routes.folders.fetchUserFolderNotification(action.data.organizationId),
      {
      },
      action.data.data
    );

    if (response.status) {
      action.data.success && action.data.success()
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* getUserFolderNotifications(action) {
  try {
    let response = yield call(
      Api.handleGet,
      routes.folders.getUserFolderNotifications(
        action.data.organizationId,
        action.data.user_folder_id      
        ),
      {}
    );

    if (response.status) {
      action.data.success && action.data.success(response.data)
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

function* deleteUserFolderNotification(action) {
  try {
    let response = yield call(
      Api.handleDelete,
      routes.folders.destroyUserFolderNotification(
        action.data.organizationId,
        action.data.id,
      ),
      {},
      {}
    );

    if (response.status) {
      action.data.success && action.data.success()
    }
    else{
      action.data.error()
    }
  } catch (error) {
    // yield put(fetchSearchERR(error))
  }
}

export function* watchAdmin() {
  yield all([
    takeLatest(FETCH_ORGANIZATIONS, fetchOrganizationsSaga),
    takeLatest(FETCH_ORGANIZATION, fetchOrganizationSaga),
    takeLatest(FETCH_UPDATE_ORGANIZATION, fetchUpdateOrganizationSaga),
    takeLatest(FETCH_ORGANIZATION_GET_ASSESSMENTS, fetchOrganizationGetAssessmentsSaga),
    takeLatest(FETCH_ORGANIZATION_CREATE, fetchOrganizationCreateSaga),
    takeLatest(FETCH_FOLDER_INDEX, fetchFolderIndex),
    takeLatest(FETCH_FOLDER, fetchFolderSaga),
    takeLatest(FETCH_FOLDER_CREATE, fetchFolderCreateSaga),
    takeLatest(FETCH_FOLDER_UPDATE, fetchFolderUpdateSaga),
    takeLatest(FETCH_COPY_CREATE, fetchFolderCopySaga),
    takeLatest(FETCH_SWITCH_CONFIG_FOLDER, fetchFolderSwitchConfigSaga),
    takeLatest(FETCH_FOLDER_DESTROY, fetchFolderDestroySaga),
    takeLatest(FETCH_FOLDER_CREATE_USERS_FOLDER, fetchFolderCreateUsersFolder),
    takeLatest(FETCH_FOLDER_UPDATE_USERS_FOLDER, fetchFolderUpdateUsersFolder),
    takeLatest(FETCH_FOLDER_DESTROY_USER, fetchFolderDestroyUser),
    takeLatest(FETCH_FOLDER_SEND_INVITATION_USER, fetchFolderSendInvitationUser),
    takeLatest(FETCH_FOLDER_START_SURVEY, fetchStartSurveyFolder),
    takeLatest(FETCH_FOLDER_UPDATE_CHART, fetchFolderUpdateChart),
    takeLatest(FETCH_FOLDER_DOWNLOAD_SCORE_USERS, fetchFolderDownloadScoreUsers),
    takeLatest(FETCH_FOLDER_DOWNLOAD_REPORT, fetchFolderDownloadReports),
    takeLatest(FETCH_EMAILS_FROM_ORGANIZATION, fetchEmailsFromOrganization),
    takeLatest(FETCH_EMAILS_CREATE, fetchEmailsCreate),
    takeLatest(FETCH_EMAILS_UPDATE, fetchEmailsUpdate),
    takeLatest(
      FETCH_EMAILS_DESTROY_FROM_ORGANIZATION,
      fetchEmailsDestroyFromOrganization
    ),
    takeLatest(FETCH_EMAILS_SHOW, fetchEmailsShow),
    takeLatest(FETCH_EMAILS_SEND, fetchEmailsSend),
    takeLatest(FETCH_USER, fetchUser),
    takeLatest(FETCH_USER_DELETE_ALL_ANSWERS, fetchUserDeleteAllAnswers),
    takeLatest(FETCH_USER_DELETE_MODULE_ANSWER, fetchUserDeleteModuleAnswer),
    takeLatest(FETCH_USER_UPDATE, fetchUserUpdate),
    takeLatest(FETCH_ADMINS, fetchAdmins),
    takeLatest(FETCH_SHOW_ADMIN, fetchShowAdmin),
    takeLatest(FETCH_ADMIN_UPDATE, fetchAdminUpdate),
    takeLatest(FETCH_ADMIN_CREATE, fetchAdminCreate),
    takeLatest(FETCH_ADMIN_DESTROY, fetchAdminDestroy),
    takeLatest(FETCH_ADMIN_SEND_EMAIL, fetchAdminSendEmail),
    takeLatest(FETCH_LOGS_FROM_ORGANIZATION, fetchLogsFromOrganization),
    takeLatest(FETCH_PROCEDURES_INDEX, fetchProceduresIndex),
    takeLatest(FETCH_PROCEDURE_CREATE, fetchProcedureCreate),
    takeLatest(FETCH_PROCEDURE, fetchProcedure),
    takeLatest(FETCH_PROCEDURE_USER, fetchProcedureUser),
    takeLatest(FETCH_UPDATE_PROCEDURE_USER, fetchUpateProcedureUser),
    takeLatest(FETCH_PROCEDURE_UPDATE, fetchProcedureUpdate),
    takeLatest(FETCH_PROCEDURE_ADD_USERS, fetchProcedureAddUsers),
    takeLatest(FETCH_PROCEDURE_REMOVE_USERS, fetchProcedureRemoveUsers),
    takeLatest(FETCH_KILLER_EXPORT, fetchKillerExport),
    takeLatest(FETCH_KILLER_FILES, fetchKillerFiles),
    takeLatest(FETCH_UPLOAD_DOCUMENT_PROCEDURE_USER, uploadDocumentProcedureUser),
    takeLatest(FETCH_DELETE_DOCUMENT_PROCEDURE_USER, deleteDocumentProcedureUser),
    takeLatest(FETCH_KILLER_EXPORT_ALL, fetchKillerExportAll),
    takeLatest(FETCH_KILLER_FILES_ALL, fetchKillerFilesAll),
    takeLatest(FETCH_EMAILS_FROM_PROCEDURE, fetchEmailsFromProcedureSaga),
    takeLatest(FETCH_EMAILS_PROCEDURE_CREATE, fetchEmailsProcedureCreateSaga),
    takeLatest(FETCH_EMAILS_PROCEDURE_UPDATE, fetchEmailsProcedureUpdateSaga),
    takeLatest(FETCH_CONSENT_PROCEDURE_UPDATE, fetchConsentProcedureUpdateSaga),
    takeLatest(FETCH_CONSENT_FOLDER_UPDATE, fetchConsentFolderUpdateSaga),
    takeLatest(FETCH_EMAILS_DESTROY_FROM_PROCEDURE, fetchEmailsDestroyFromProcedureSaga),
    takeLatest(FETCH_EMAILS_PROCEDURE_SHOW, fetchEmailsProcedureShowSaga),
    takeLatest(FETCH_EMAILS_PROCEDURE_SEND, fetchEmailsProcedureSendSaga),
    takeLatest(FETCH_EMAILS_TEST_SEND, fetchEmailsSendTestSaga),
    takeLatest(FETCH_RANKING_PROFILE, fetchRankingProfileSaga),
    takeLatest(FETCH_RANKING_PROFILE_ID, fetchRankingProfileIdSaga),
    takeLatest(FETCH_UPDATE_RANKING_PROFILE, fetchUpdateRankingProfileSaga),
    takeLatest(FETCH_DESTROY_RANKING_PROFILE, fetchDestroyRankingProfileSaga),
    takeLatest(FETCH_CREATE_RANKING_PROFILE, fetchCreateRankingProfileSaga),
    takeLatest(FETCH_RANKED_USERS, fetchRankedUsersSaga),
    takeLatest(FETCH_MOVE_USERS, fetchMoveUsersSaga),
    takeLatest(FETCH_USER_FOLDER_NOTIFICATION, fetchUserFolderNotification),
    takeLatest(GET_USER_FOLDER_NOTIFICATIONS, getUserFolderNotifications),
    takeLatest(DELETE_USER_FOLDER_NOTIFICATIONS, deleteUserFolderNotification)
  ]);
}
