// Dependencies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Close = (props) => {
  const [closeClasses, setCloseClasses] = useState("c-close");
  const [closeClassesIllustration, setCloseClassesIllustration] = useState(
    "c-close__illustration"
  );

  useEffect(() => {
    setCloseClasses(
      classNames(
        "c-close",
        props.background && "c-close--bg",
        props.rotation && "c-close--rotation",
        props.medium && "c-close--medium",
        props.big && "c-close--big",
        props.extraBig && "c-close--extra-big",
        props.width && props.height && "c-close--size-auto",
        props.className
      )
    );
    setCloseClassesIllustration(
      classNames(
        "c-close__illustration",
        props.closeClasses && props.closeClasses
      )
    );
  }, []);

  return (
    <div className={closeClasses} style={props.style ? props.style : {}}>
      <svg
        width={props.width ? props.width : "20px"}
        height={props.height ? props.height : "20px"}
        viewBox="0 0 20 20"
        className={closeClassesIllustration}
      >
        <path
          fill={props.color ? props.color : "#FDFDFD"}
          fillRule="evenodd"
          d="M10 .515a1 1 0 0 1 1 1V9h7.485a1 1 0 1 1 0 2H11v7.485a1 1 0 0 1-2 0V11H1.515a1 1 0 0 1 0-2H9V1.515a1 1 0 0 1 1-1Z"
        />
      </svg>
    </div>
  );
};

Close.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

export default Close;
